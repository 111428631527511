import Vue from 'vue';
import endpointService from '../endpoint.service';
import { TeamViewObject, CreateTeamRequest, UpdateTeamRequest } from '@/_typedef/missile';

interface ITeamsState {
    teams: TeamViewObject[];
}

class TeamsService {
    private state: ITeamsState = Vue.observable({
        teams: []
    });

    public get teams(): TeamViewObject[] {
        return this.state.teams;
    }

    public async getAll(): Promise<any[]> {
        const res = await endpointService.get<TeamViewObject[]>('teams');

        this.state.teams = res;

        return res;
    }

    public async create(payload: CreateTeamRequest): Promise<void> {
        const res = await endpointService.post<TeamViewObject[]>('teams', payload);

        this.state.teams = res;
    }

    public async update(id: number, payload: UpdateTeamRequest): Promise<void> {
        const res = await endpointService.put<TeamViewObject[]>(`teams/${id}`, payload);

        this.state.teams = res;
    }

    public async makeDefaultOption(id: number): Promise<void> {
        const res = await endpointService.post<TeamViewObject[]>(`teams/${id}/default`);

        this.state.teams = res;
    }

    public async delete(id: number): Promise<void> {
        const res = await endpointService.delete<TeamViewObject[]>(`teams/${id}`);

        this.state.teams = res;
    }
}

export default new TeamsService();