import { formatDate } from '@/core/formatters/formatDate';
import _Vue from 'vue';

export default {
    install: (Vue: typeof _Vue, options?: any) => {
        Vue.prototype.$formatDate = formatDate;
    }
};

declare module 'vue/types/vue' {
    interface Vue {
        $formatDate: (value: Date | string, format?: string) => string;
    }
}