<template>
    <div
        v-if="isAuthenticated"
        class="nav">
        <RouterLink
            to="/"
            class="nav__logo h-full flex items-center">
            <span
                class="logo rounded-lg bg-indigo-800 text-white-100 h-12 w-12 flex items-center justify-center leading-none pt-1 text-3xl">M</span>
        </RouterLink>
        <RouterLink
            to="/"
            class="flex flex-col justify-center items-center">
            <i class="material-icons">date_range</i>

            <div>Kalender</div>
        </RouterLink>
        <RouterLink
            to="/teams"
            class="flex flex-col justify-center items-center">
            <i class="material-icons">people_alt</i>

            <div>Hold</div>
        </RouterLink>
        <RouterLink
            to="/tasktypes"
            class="flex flex-col justify-center items-center">
            <i class="material-icons">assignment</i>

            <div>Opgavetyper</div>
        </RouterLink>
        <RouterLink
            to="/account"
            class="flex flex-col justify-center items-center">
            <i class="material-icons">account_box</i>

            <div>Konto</div>
        </RouterLink>

        <div class="flex-grow"></div>

        <a
            class="flex flex-col justify-center items-center curser-pointer"
            href="#"
            @click.prevent="signOut">
            <i class="material-icons">exit_to_app</i>

            <div>Log ud</div>
        </a>
    </div>
</template>

<script lang="ts" setup>
import router from '@/router/router';
import authenticationService from '@/core/services/account/authentication.service';
import { computed } from 'vue';

const isAuthenticated = computed(() => authenticationService.context.isAuthenticated);

function signOut(): void {
    authenticationService.signOut();
    router.push({ name: 'login' });
}
</script>