import axios from 'axios';
import authenticationService from './account/authentication.service';
import bus from '../Bus';
import router from '@/router/router';
import apiVersionInterceptor from '@/core/services/api-version.interceptor';

class EndpointService {
    async get<T>(url: string, params?: any): Promise<T> {
        try {
            const response = await this.axios.get<any>(this.apiPath(url), { params: params });
            return response.data as T;
        } catch (error) {
            this.handleError(error);

            throw error;
        }
    }

    async post<T>(url: string, payload?: any, validationGroup?: string): Promise<T> {
        try {
            const options: any = {};
            if (validationGroup) {
                options.headers = { 'X-Validation': validationGroup };
            }

            const response = await this.axios.post<any>(this.apiPath(url), payload, options);
            return response.data as T;
        } catch (error: any) {
            this.handleError(error);

            throw error.response.data;
        }
    }

    async put<T>(url: string, payload?: any, validationGroup?: string): Promise<T> {
        try {
            const options: any = {};
            if (validationGroup) {
                options.headers = { 'X-Validation': validationGroup };
            }

            const response = await this.axios.put<any>(this.apiPath(url), payload, options);
            return response.data as T;
        } catch (error: any) {
            this.handleError(error);

            throw error;
        }
    }

    async delete<T>(url: string, params?: any): Promise<T> {
        try {
            const response = await this.axios.delete<any>(this.apiPath(url), { params: params });
            return response.data as T;
        } catch (error: any) {
            this.handleError(error);

            throw error;
        }
    }

    private handleError(error: any) {
        if (error.response.headers['x-validation'] && error.response.status === 400) {
            // Component will show validation errors
            return;
        }

        if (error.response.status === 400) {
            bus.toasterMessage({
                messages: error.response.data,
                type: 'error'
            });
        } else if (error.response.status === 401) {
            const authErrorHeader = error.response.headers['www-authenticate'];

            if (authErrorHeader && authErrorHeader.indexOf('invalid_token') > -1) {
                authenticationService.signOut();
                router.push({ name: 'login' });

                return;
            }

            bus.toasterMessage({
                messages: ['Du mangler adgang til at kunne udføre denne handling'],
                type: 'error'
            });
        } else {
            bus.toasterMessage({
                messages: ['Server fejl - prøv igen'],
                type: 'error'
            });
        }
    }

    private apiPath(relativePath: string): string {
        return `/api/${relativePath}`;
    }

    private get axios() {
        const headers = {
            'Accept-Language': 'da-DK'
        } as any;

        if (authenticationService.context.isAuthenticated) {
            headers.Authorization = `Bearer ${authenticationService.token}`;
        }

        const api = axios.create({
            headers: headers
        });

        api.interceptors.response.use(apiVersionInterceptor);

        return api;
    }
}

export default new EndpointService();