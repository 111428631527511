<template>
    <transition
        name="fade"
        mode="in-out">
        <div
            v-if="showing"
            class="fixed z-10 inset-0 overflow-y-auto bg-semi-75 z-40"
            @click.self="close">
            <div
                class="relative p-6 w-full h-full bg-white mx-auto xl:shadow-lg xl:rounded-lg xl:h-auto xl:max-w-2xl xl:mt-10"
                @keydown.esc="close">
                <button
                    aria-label="close"
                    class="leading-tight absolute top-0 right-0 text-4xl text-black my-4 mr-2 px-2"
                    @click.prevent="close">
                    ×
                </button>

                <slot/>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
    name: 'v-modal'
})
export default class Modal extends Vue {
    @Prop({ default: false }) showing: boolean;

    close() {
        this.$emit('close');
    }

    @Watch('showing')
    scrollHandler(value: boolean): void {
        const body = document.querySelector('html');

        if (body == null) return;

        if (value) {
            body.classList.add('modal-body');

            return;
        }

        body.classList.remove('modal-body');
    }
}
</script>

<style lang="scss">
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    overflow-y: scroll;
}
</style>