<template>
    <div>
        <validation-provider
            v-slot="{ errors }"
            :rules="rule"
            :name="name">
            <select
                :id="id"
                :value="value"
                :class="{ error: errors[0] }"
                @change="handleInput">
                <slot/>
            </select>
            <span
                v-if="errors[0]"
                class="text-red-500 text-xs">{{ errors[0] }}</span>
        </validation-provider>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'VSelect'
})
export default class VSelect extends Vue {
    @Prop() name: string;
    @Prop() id: string;
    @Prop() value: any;
    @Prop() rule: string;

    handleInput(event: any) {
        this.$emit('input', event.target.value);
    }
}
</script>