<template>
    <div>
        <label v-if="label">
            {{ label }}
        </label>

        <validation-provider
            v-slot="{ errors }"
            :rules="rule"
            :name="name">
            <input
                :id="id"
                :type="type"
                :value="value"
                :placeholder="placeholder"
                :disabled="disabled"
                :class="{ 'error': errors[0] }"
                @input="handleInput">
            <span class="text-red-500 text-xs">{{ errors[0] }}</span>
        </validation-provider>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'v-input'
})
export default class VInput extends Vue {
    @Prop({ default: 'text' }) type: string;
    @Prop() placeholder: string;
    @Prop() name: string;
    @Prop() id: string;
    @Prop() value: string;
    @Prop() label: string;
    @Prop() rule: string;
    @Prop() disabled: boolean;

    handleInput(event: any) {
        this.$emit('input', event.target.value);
    }
}
</script>