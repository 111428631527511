export function formatNumber(value: number) {
    if (typeof value !== 'number') {
        return value;
    }

    return new Intl.NumberFormat('da-DK', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(value);
}