import Vue from 'vue';
import Modal from '@/components/globals/Modal.vue';
import VInput from '@/components/globals/VInput.vue';
import VSelect from '@/components/globals/VSelect.vue';

import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: '{_field_} er påkrævet'
});

Vue.component('VModal', Modal);
Vue.component('VInput', VInput);
Vue.component('VSelect', VSelect);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);