import _Vue from 'vue';
import { formatNumber } from '@/core/formatters/formatNumber';

export default {
    install: (Vue: typeof _Vue, options?: any) => {
        Vue.prototype.$formatNumber = formatNumber;
    }
};

declare module 'vue/types/vue' {
    interface Vue {
        $formatNumber: (value: number) => string;
    }
}