import Vue from 'vue';
import endpointService from '../endpoint.service';
import { TaskTypeViewObject, CreateTaskTypeRequest, UpdateTaskTypeRequest } from '@/_typedef/missile';

interface ITaskTypesState {
    taskTypes: TaskTypeViewObject[];
}

class TaskTypesService {
    private state: ITaskTypesState = Vue.observable({
        taskTypes: []
    });

    public get taskTypes(): TaskTypeViewObject[] {
        return this.state.taskTypes;
    }

    public async getAll(): Promise<any[]> {
        const res = await endpointService.get<TaskTypeViewObject[]>('tasktypes');

        this.state.taskTypes = res;

        return res;
    }

    public async create(payload: CreateTaskTypeRequest): Promise<void> {
        const res = await endpointService.post<TaskTypeViewObject[]>('tasktypes', payload);

        this.state.taskTypes = res;
    }

    public async update(id: number, payload: UpdateTaskTypeRequest): Promise<void> {
        const res = await endpointService.put<TaskTypeViewObject[]>(`tasktypes/${id}`, payload);

        this.state.taskTypes = res;
    }

    public async delete(id: number): Promise<void> {
        const res = await endpointService.delete<TaskTypeViewObject[]>(`tasktypes/${id}`);

        this.state.taskTypes = res;
    }

    public async makeDefaultOption(id: number): Promise<void> {
        const res = await endpointService.post<TaskTypeViewObject[]>(`tasktypes/${id}/default`);

        this.state.taskTypes = res;
    }
}

export default new TaskTypesService();