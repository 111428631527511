<template>
    <div class="font-sans h-full flex flex-col">
        <TopNavigation/>

        <Transition
            name="fade"
            mode="out-in">
            <router-view/>
        </Transition>

        <MessageToaster/>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import MessageToaster from '@/components/MessageToaster.vue';
import authenticationService from '@/core/services/account/authentication.service';
import teamsService from '@/core/services/teams/teams.service';
import taskTypesService from '@/core/services/tasktypes/taskTypes.service';
import router from '@/router/router';
import TopNavigation from './components/TopNavigation.vue';

const isAuthenticated = computed(() => authenticationService.context.isAuthenticated);

created();
async function created() {
    if (isAuthenticated.value) {
        try {
            await authenticationService.renew();
        } catch {
            signOut();
        }

        await teamsService.getAll();
        await taskTypesService.getAll();
    }
}

function signOut(): void {
    authenticationService.signOut();
    router.push({ name: 'login' });
}
</script>