import Vue from 'vue';
import VueRouter from 'vue-router';
import authenticationService from '@/core/services/account/authentication.service';

Vue.use(VueRouter);

const ifAuthenticated = (_to: any, _from: any, next: any) => {
    if (authenticationService.context.isAuthenticated) {
        next();
        return;
    }
    next('/login');
};

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
            title: 'Kalender'
        }
    },
    {
        path: '/teams',
        name: 'teams',
        component: () => import('@/views/Teams.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
            title: 'Hold'
        }
    },
    {
        path: '/tasktypes',
        name: 'tasktypes',
        component: () => import('@/views/TaskTypes.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
            title: 'Opgavetyper'
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('@/views/Account.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
            title: 'Konto'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/authentication/pages/LoginPage.vue'),
        meta: {
            title: 'Velkommen'
        }
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('@/views/authentication/pages/SignUp.vue'),
        meta: {
            title: 'Tilmeld dig'
        }
    },
    {
        path: '*',
        name: 'not-found',
        component: () => import('@/views/NotFound.vue'),
        meta: {
            title: 'Siden blev ikke fundet'
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});

router.afterEach((to, _) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title + ' · Missile';
    }
});

export default router;