import mitt from 'mitt';

export interface IToasterMessage {
    messages: string[];
    type: 'info' | 'success' | 'error';
}

const CustomToasterMessage = 'ToasterMessage';

class Bus {
    private emitter = mitt();

    public emit(event: string, ...args: any[]): void {
        this.emitter.emit(event, args);
    }

    public on(event: string, callback: (...args: any[]) => void): void {
        this.emitter.on(event, callback);
    }

    public off(event: string, callback: (...args: any[]) => void): void {
        this.emitter.off(event, callback);
    }

    public toasterMessage(data: IToasterMessage) {
        this.emitter.emit(CustomToasterMessage, data);
    }

    public success(message: string) {
        this.toasterMessage({ messages: [message], type: 'success' });
    }

    public info(message: string) {
        this.toasterMessage({ messages: [message], type: 'info' });
    }

    public onToasterMessage(callback: (data: IToasterMessage) => void) {
        this.on(CustomToasterMessage, callback);
    }
}

export default new Bus();