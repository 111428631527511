<template>
    <div
        v-if="showToaster"
        class="error flex items-center text-white z-60 bottom-0 inset-x-0 justify-between fixed pb-1">
        <div
            class="toaster py-2 px-3 container mx-auto info rounded"
            :class="type">
            <i
                class="material-icons cursor-pointer float-right"
                @click="closeToaster">close</i>

            <span
                v-for="message in messages"
                :key="message"
                class="block">
                {{ message }}
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import bus, { IToasterMessage } from '@/core/Bus';

@Component({
    name: 'c-message-toaster'
})
export default class MessageToaster extends Vue {
    showToaster: boolean = false;
    messages: string[] = [];
    type: string = '';

    mounted() {
        bus.onToasterMessage((customToasterData: IToasterMessage) => {
            this.messages = customToasterData.messages;
            this.type = customToasterData.type;
            this.showToaster = true;

            setTimeout(() => {
                return (this.showToaster = false);
            }, 4000);
        });
    }

    closeToaster(): void {
        this.showToaster = false;
    }
}
</script>