import clientContext from '@/core/client-context/client-context';

export default function(response: any) {
    const appVersion = response.headers['x-appversion'];

    if (appVersion && appVersion !== clientContext.version) {
        window.location.reload();
    }

    return response;
}