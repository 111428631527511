import Vue from 'vue';
import App from './App.vue';
import './styling/main.scss';
import router from './router/router';
import './components/globals/global.components';
import moment from 'moment';
import formatNumber from './plugins/formatNumber';
import formatDate from './plugins/formatDate';

Vue.config.productionTip = false;

moment.locale('da-dk');

Vue.use(formatNumber);
Vue.use(formatDate);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');