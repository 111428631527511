import Vue from 'vue';
import endpointService from '../endpoint.service';
import { UserContext, AuthenticationViewModel, RegisterRequest, UpdateAccountRequest, AuthenticateRequest } from '@/_typedef/missile';

interface IAuthenticateState {
    context: UserContext;
    token: string;
}

class AuthenticationService {
    private readonly localStorageKey = 'missile_authentication';

    private state: IAuthenticateState = Vue.observable({
        context: { isAuthenticated: false } as UserContext,
        token: ''
    });

    constructor() {
        const authenticationState = localStorage.getItem(this.localStorageKey);
        if (authenticationState) {
            const authentication = JSON.parse(authenticationState);
            this.setAuthentication(authentication);
        }
    }

    public get context(): UserContext {
        return this.state.context;
    }

    public get token(): string {
        return this.state.token;
    }

    public async authenticate(request: AuthenticateRequest): Promise<AuthenticationViewModel> {
        const res = await endpointService.post<AuthenticationViewModel>('authentication', request, 'Login');

        this.persistAuthenticationState(res);

        return res;
    }

    public async register(request: RegisterRequest): Promise<AuthenticationViewModel> {
        const res = await endpointService.post<AuthenticationViewModel>('authentication/register', request, 'register');

        this.persistAuthenticationState(res);

        return res;
    }

    public async renew(): Promise<AuthenticationViewModel> {
        const res = await endpointService.post<AuthenticationViewModel>('authentication/renew', undefined, 'renew');

        this.persistAuthenticationState(res);

        return res;
    }

    public signOut(): void {
        localStorage.removeItem(this.localStorageKey);

        this.state.token = '';
        this.state.context = { isAuthenticated: false } as UserContext;
    }

    public async updateAccount(request: UpdateAccountRequest): Promise<AuthenticationViewModel> {
        const res = await endpointService.post<AuthenticationViewModel>('account', request);

        this.persistAuthenticationState(res);

        return res;
    }

    private setAuthentication(authentication: AuthenticationViewModel) {
        this.state.context = authentication.context;
        this.state.token = authentication.token;
    }

    private persistAuthenticationState(authentication: AuthenticationViewModel) {
        this.setAuthentication(authentication);

        localStorage.setItem(this.localStorageKey, JSON.stringify(authentication));
    }
}

export default new AuthenticationService();